/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import Provider from "./src/provider"
// You can delete this file if you're not using it
// export const wrapRootElement = Provider

exports.onRouteUpdate = () => {
  if (window.CallTrkSwap) {
    window.CallTrkSwap.swapEntry()
  }
}
