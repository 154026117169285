import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  navHeight: 62,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primaryFont: "DM Sans, sans-serif",
    secondaryFont: "Merriweather, sans-serif",
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#0F1923",
      light: "#1A2933",
      dark: "#091118",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#F54343",
      light: "#F54343",
      dark: "#F54343",
    },
    tertiary: {
      main: "#2C9ED0",
    },
    text: {
      primary: "#10355A",
      secondary: "#0F1923",
      content: "#787C80",
      tertiary: "#1A2933",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#EEEEEE",
    },
  },
  typography: {
    fontFamily: `"DM Sans", "Open Sans", "Arial", sans-serif`,
  },
});
export default theme;
